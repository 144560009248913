import { API_URL } from "../../constants/api-url";
import { fetchAPI } from "../api-utils";

const _baseUrl = `${API_URL.backend_url}order-service/reports/`;

export const getSalesReportApi = async ({ startDate, endDate, salesLevel }) => {
  const resp = await fetchAPI({
    url: `${_baseUrl}sales?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&salesLevel=${salesLevel}`,
  });

  return resp;
};


// todo
export const getOutletOrderReportApi = async ({ startDate, endDate, beetId }) => {
  const resp = await fetchAPI({
    url: `${_baseUrl}getOutletOrderReportByBeetIdWithDateFilter/${beetId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&page=0&pageSize=1000`,
  });

  return resp;
};

export const getBeetOrderReportByReportingManagerApi = async ({ startDate, endDate, reportingManagerId }) => {
  const resp = await fetchAPI({
    url: `${_baseUrl}getBeetOrderReportByReportingManagerIdWithDateFilter/${reportingManagerId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&page=0&pageSize=1000`,
  });

  return resp;
};

export const getBeetOrderReportByMemberIdApi = async ({ startDate, endDate, memberId }) => {
  const resp = await fetchAPI({
    url: `${_baseUrl}getBeetOrderReportByMemberIdWithDateFilter/${memberId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&page=0&pageSize=1000`,
  });

  return resp;
};



// outlet

export const getAllOrderByEachOutletByMemberIdByProductiveStatusApi = async (memberId, callStatus) => {
  const resp = await fetchAPI({
    url: `${_baseUrl}getAllOrderByEachOutletByMemberIdByProductiveStatus/${memberId}?orderCallStatus=${callStatus}&page=0&pageSize=1000`,
  });

  return resp;
};

export const getAllOrderByEachOutletByMemberIdByOrderMediumApi = async ( memberId, orderMedium) => {
  const resp = await fetchAPI({
    url: `${_baseUrl}getAllOrderByEachOutletByMemberIdByOrderMedium/${memberId}?orderMedium=${orderMedium}&page=0&pageSize=1000`,
  });

  return resp;
};
export const getAllOrderByEachOutletByClientFMCGIdByProductiveStatusApi = async (clientFmcgId, callStatus ) => {
  const resp = await fetchAPI({
    url: `${_baseUrl}getAllOrderByEachOutletByClientFmcgIdByProductiveStatus/${clientFmcgId}?orderCallStatus=${callStatus}&page=0&pageSize=1000`,
  });

  return resp;
};

export const getAllOrderByEachOutletByClientFMCGIdByOrderMediumApi = async ( clientFmcgId, orderMedium ) => {
  const resp = await fetchAPI({
    url: `${_baseUrl}getAllOrderByEachOutletByClientFmcgIdByOrderMedium/${clientFmcgId}?orderMedium=${orderMedium}&page=0&pageSize=1000`,
  });

  return resp;
};


// beet
export const getAllOrderByEachBeetByMemberIdByProductiveStatusApi = async ( memberId, callStatus) => { 
  const resp = await fetchAPI({
    url: `${_baseUrl}getAllOrderByEachBeetByMemberIdByProductiveStatus/${memberId}?orderCallStatus=${callStatus}&page=0&pageSize=1000`,
  });

  return resp;
};

export const getAllOrderByEachBeetByMemberIdByOrderMediumApi = async ( memberId, orderMedium ) => {
  const resp = await fetchAPI({
    url: `${_baseUrl}getAllOrderByEachBeetByMemberIdByOrderMedium/${memberId}?orderMedium=${orderMedium}&page=0&pageSize=1000`,
  });

  return resp;
};
export const getAllOrderByEachBeetByClientFMCGIdByProductiveStatusApi = async ( clientFmcgId, callStatus ) => {
  const resp = await fetchAPI({
    url: `${_baseUrl}getAllOrderByEachBeetByClientFmcgIdByProductiveStatus/${clientFmcgId}?orderCallStatus=${callStatus}&page=0&pageSize=1000`,
  });

  return resp;
};

export const getAllOrderByEachBeetByClientFMCGIdByOrderMediumApi = async ( clientFmcgId, orderMedium ) => {
  const resp = await fetchAPI({
    url: `${_baseUrl}getAllOrderByEachBeetByClientFmcgIdByOrderMedium/${clientFmcgId}?orderMedium=${orderMedium}&page=0&pageSize=1000`,
  });

  return resp;
};
