import React from "react";
import { useSaleDetails } from "./useSaleDetails";
import PageHeader from "../../../components/common/PageHeader";
import DataTable from "react-data-table-component";
import { Button, Spinner } from "react-bootstrap";
import PrimarySaleUpdateModal from "../PrimarySales/form/update-pop-up";
import SaleDetail from "../PrimarySales/sale-detail";
import "../index.css";
function SaleDetailsPage() {
  const {
    sales_col,
    allSales,
    loading,
    editOrderData,
    setEditOrderData,
    setAllSales,
    setViewData,
    viewData,
    invoiceNumber,
    generateInvoice,
  } = useSaleDetails();

  function handleUpdate(order) {
    setAllSales(allSales.map((e) => (e.orderId === order.orderId ? {...e,order} : e)));
  }

  return (
    <>
      <div className="container-xxl">
        <PageHeader
          headerTitle={`Invoice Detail`}
          renderRight={() => {
            return <Button onClick={generateInvoice}>Download</Button>;
          }}
        />
        <div className="mb-3">
          <strong>Invoice No:</strong> <span>{invoiceNumber || "N/A"}</span>
        </div>
        <div className="container mt-4 ">
          <DataTable
            columns={sales_col}
            data={allSales}
            pagination
            style={{
              tableLayout: {
                backgroundColor: "red",
              },
            }}
            highlightOnHover
            responsive
            progressPending={loading === 0}
            progressComponent={
              <div className="text-center py-3">
                <Spinner animation="border" size="lg" role="status" />
                <p>Loading orders...</p>
              </div>
            }
            className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
            customStyles={{
              headCells: {
                style: {
                  backgroundColor: "#f1f5f9",
                  fontWeight: "bold",
                },
              },
              rows: {
                style: {
                  minHeight: "72px",
                },
              },
            }}
          />
        </div>
        <PrimarySaleUpdateModal
          isVisible={Boolean(editOrderData)}
          orderData={editOrderData}
          setVisible={() => setEditOrderData(null)}
          isPrimary={true}
          noRedux
          onUpdate={handleUpdate}
        />
        <SaleDetail
          visible={viewData.visible}
          isPrimary
          onHide={() => {
            setViewData({
              data: null,
              loading: false,
              visible: false,
            });
            console.log("called");
          }}
          saleData={viewData}
          setSaleData={setViewData}
        />
      </div>
    </>
  );
}

export default SaleDetailsPage;
