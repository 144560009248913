import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner, Toast } from "react-bootstrap";
import PageHeader from "../../components/common/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/UI/Loading";
import DataTable from "react-data-table-component";
import AddUpdateOutlet from "./Form/AddUpdateOutlet";
import Swal from "sweetalert2";
import { permissionIds } from "../../constants/constants";

function BeetOutlet({
  outlets,
  beetId,
  handleOutletViewModal,
  handleGetUserLocation,
  locationError,
  latitude,
  longitude,
}) {
  //Redux
  const Dispatch = useDispatch();
  const Beet = useSelector((state) => state.Beets);
  const CredDetails = useSelector((state) => state.Cred);
  const { memberPermissions } = useSelector((state) => state.Permission);

  // UI Manipulation Variables
  const [isModal, setIsModal] = useState(false);
  const [isOutletModal, setIsOutletModal] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [buttonLoader, setButtonLoader] = useState({
    getDropDowns: false,
    addClient: false,
  });

  //Pagination Variables
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(20);

  // console.log('outlets',outlets)
  // console.log('beetId',beetId)
  // API functions

  const handleDeleteOutlet = async (data) => {
    handleisModalDelete();
    try {
      const resp = true; // api call
      if (resp) {
        Dispatch();
        Swal.fire("Success", "Outlet Deleted Successfully");
        handleisModalDelete();
      }
    } catch (error) {
      console.log("Error Deleting Outlet: ", error);
      Swal.fire("Error", "Unable to delete Outlet !");
    }
  };

  // UI Modify functions

  function handleIsModal() {
    setIsModal(!isModal);
  }

  function handleisModalDelete() {
    setIsModalDelete(!isModalDelete);
  }

  //React Table Data Row
  const columns = [
    {
      name: "Outlet Name",
      selector: (row) => row.outletName,
      sortable: false,
      grow: 2, // Adjust the grow factor to make this column wider
    },
    {
      name: "Outlet Owner Name",
      selector: (row) => row.ownerName,
      sortable: true,
      grow: 2,
    },
    {
      name: "Outlet Type",
      selector: (row) => row.outletType,
      sortable: false,
      grow: 1,
    },
    // {
    //   name: "",
    //   cell: (row) => (
    //     <div
    //       className="btn-group mt-2 d-flex gap-2"
    //       role="group"
    //       aria-label="Basic outlined example"
    //     >
    //       <button
    //         type="button"
    //         onClick={handleIsModal}
    //         className="btn btn-outline-secondary text-success d-flex gap-2 justify-content-center align-items-center"
    //       >
    //         <i className="icofont-edit text-success"></i>
    //       </button>
    //       <button
    //         type="button"
    //         onClick={() => handleDeleteOutlet(row)}
    //         className="btn btn-outline-secondary text-danger d-flex gap-2 justify-content-center align-items-center"
    //       >
    //         <i className="icofont-bin text-danger"></i>
    //       </button>
    //     </div>
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // },
  ];

  return (
    <>
      {loading ? (
        <Loading animation={"border"} color={"black"} />
      ) : (
        <div className="container-xxl">
          <PageHeader
            headerTitle="Outlets"
            renderRight={() => {
              return (
                <div className="col-auto d-flex">
                  {(memberPermissions.some(
                    (item) => item === permissionIds.MANAGER
                  ) ||
                    memberPermissions.some(
                      (item) => item === permissionIds.CREATE_MANAGER
                    )) && (
                    <Button
                      variant="primary"
                      onClick={async () => {
                        await handleGetUserLocation();
                        handleIsModal();
                        if (locationError) {
                          handleIsModal();
                          Swal.fire({
                            title: "Location Error",
                            text: locationError,
                            timer: 2000,
                            icon: "error",
                          });
                          handleIsModal();
                        }
                      }}
                      className="btn btn-primary"
                    >
                      {buttonLoader.getDropDowns ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-1"
                        />
                      ) : (
                        <i className="icofont-plus-circle me-2 fs-6"></i>
                      )}
                      Add Outlet
                    </Button>
                  )}
                </div>
              );
            }}
          />

          <div className="row g-3 row-cols-1 py-1 pb-4">
            <div className="col-auto d-flex flex-wrap"></div>
            {outlets?.length > 0 ? (
              <div className="col-sm-12">
                <div className="w-100">
                  <div className="row clearfix g-3">
                    <div className="col-sm-12">
                      <DataTable
                        id="Data_table"
                        columns={columns}
                        data={outlets}
                        defaultSortField="outletName"
                        className="table myDataTable table-hover align-middle mb-0 w-100 d-row nowrap dataTable no-footer dtr-inline"
                        highlightOnHover={true}
                        pagination
                        paginationServer
                        paginationTotalRows={outlets.length}
                        paginationComponentOptions={{
                          noRowsPerPage: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                No Outlets To Load.
              </p>
            )}
          </div>
        </div>
      )}

      <Modal size="xl" centered show={isModal} onHide={handleIsModal}>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">
            {editData ? "Edit Outlet" : "Add Outlet"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddUpdateOutlet
            editData={null}
            handleIsModal={handleIsModal}
            beetId={beetId}
            outletData={outlets}
            handleOutletViewModal={handleOutletViewModal}
            longitude={longitude}
            latitude={latitude}
          />
        </Modal.Body>
      </Modal>

      {/* <Modal show={isModalDelete} centered onHide={handleisModalDelete}>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Delete Outlet</Modal.Title>
        </Modal.Header>
        <Modal.Body className="justify-content-center flex-column d-flex">
          <i className="icofont-ui-delete text-danger display-2 text-center mt-2"></i>
          <p className="mt-4 fs-5 text-center">
            You can only delete this Outlet Permanently !
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setIsModalDelete(false);
            }}
          >
            Cancel
          </button>
          <Button
            variant="primary"
            className="btn btn-danger color-fff"
            onClick={handleisModalDelete}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-1"
              />
            ) : (
              "Delete"
            )}
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default BeetOutlet;
