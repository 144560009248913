import axios from "axios";
import { API_URL} from "../../constants/api-url"


export async function loginMember(mobile,password){
        const url = API_URL.backend_url + "authenticate";
        var header = {
            "Content-type": "application/json",        }
      const response =  await axios(
        {
            headers:header,
            url:url,
            method:"POST",
            data:{
                mobile:mobile,
                password:password.toString()
            },
            timeout:30000,
            timeoutErrorMessage:"Time Out. Please Try With Some Better Network"

        }
      )
    //   console.log(response)
      return {token:response.data,statusCode:response.status}
   
}
export async function loginClientFMCG(clientCode,password){
        const url = API_URL.backend_url + "client-fmcg/clientFmcgLogin";
        var header = {
            "Content-type": "application/json",        }
      const response =  await axios(
        {
            headers:header,
            url:url,
            method:"POST",
            data:{
                clientCode:clientCode,
                password:password.toString()
            },
            timeout:30000,
            timeoutErrorMessage:"Time Out. Please Try With Some Better Network"

        }
      )
    //   console.log(response)
      return {token:response.data,statusCode:response.status}
   
}
