export function getDaysInMonth(month, year) {
  if (month < 1 || month > 12) {
    return -1;
  }
  if (!year) {
    year = new Date().getFullYear();
  }
  const lastDay = new Date(year, month, 0);
  return lastDay.getDate();
}

export function getDateFormat(currentDate) {
  currentDate = new Date(currentDate);
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function getNumberOfDays(startDate, endDate) {
  const startMillis = startDate.getTime();
  const endMillis = endDate.getTime();
  const differenceMillis = endMillis - startMillis;
  const daysDifference = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));
  const numberOfDays = daysDifference;

  return numberOfDays + 1;
}

export function getTimeFormat(now) {
  let hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";

  hours = hours % 12 || 12;

  return `${hours}:${minutes} ${period}`;
}
