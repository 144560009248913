import React from "react";
import { Route, Routes } from "react-router-dom";
import Members from "../Employee/Members";
import Expenses from "../Accounts/Expenses";
import Attendance from "../Employee/Attendance";
import Clients from "../Our Clients/Clients";
import Calendar from "../App/Calendar";
import Schedules from "../Employee/Schedules";
import Profile from "../Employee/Profile";
import LeaveRequest from "../Employee/LeaveRequest";
import Status from "../Status/Status";
import DCR from "../DCR/DCR";
import ProductPrice from "../Products/Price/ProductPrice";
import Inventory from "../Inventory/Inventory";
import ProductMaster from "../Products/Master/ProductMaster";
import PrimarySales from "../Sales/PrimarySales/PrimarySales";
import Beet from "../Beet/Beet";
import Tenant from "../Tenant/Tenant";
import RegionMaster from "../Master/RegionMaster";
import StateMaster from "../Master/StateMaster";
import CityMaster from "../Master/CityMaster";
import SecondarySales from "../Sales/SecondarySales/SecondarySales";
import Holidays from "../Employee/Holidays";
import Page404 from "../../components/Auth/Page404";
import FMCGClients from "../Our Clients/FMCGClients";
import ProtectedRoute from "./route-util";
import SalesReport from "../reports/sales-report";
import TimeIntervalReports from "../reports/TimeIntervalReports";
import AllOrderReport from "../reports/AllOrdersReports";
import SaleDetailsPage from "../Sales/sales-detail/SaleDetailsPage";

function MemberRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Members />} />
      <Route path="expenses" element={<Expenses />} />
      <Route path="attendance" element={<Attendance />} />
      <Route path="/clientsFmcg" element={<FMCGClients />} />
      <Route path="clients" element={<Clients />} />
      <Route path="calender" element={<Calendar />} />
      <Route path="members-scheduler" element={<Schedules />} />
      <Route path="member-profile" element={<Profile />} />
      <Route path="leave-request" element={<LeaveRequest />} />
      <Route path="status-report" element={<Status />} />
      <Route path="call-report" element={<DCR />} />
      <Route path="productprice" element={<ProductPrice />} />
      <Route path="productmaster" element={<ProductMaster />} />
      <Route path="primarySales" element={<PrimarySales />} />
      <Route path="beets" element={<Beet />} />
      <Route path="tenant" element={<Tenant />} />
      <Route path="secondarySales" element={<SecondarySales />} />
      <Route path="holidays" element={<Holidays />} />
      <Route path="*" element={<Page404 />} />

      {/* Procted routes */}

      <Route
        path="regionMaster"
        element={<ProtectedRoute element={<RegionMaster />} />}
      />
      <Route
        path="stateMaster"
        element={<ProtectedRoute element={<StateMaster />} />}
      />
      <Route
        path="cityMaster"
        element={<ProtectedRoute element={<CityMaster />} />}
      />
      <Route
        path="sales-report"
        element={<ProtectedRoute element={<SalesReport />} />}
      />
      <Route
        path="time-interval-report"
        element={<ProtectedRoute element={<TimeIntervalReports />} />}
      />
      <Route
        path="all-order-report"
        element={<ProtectedRoute element={<AllOrderReport />} />}
      />
      <Route path="inventory" element={<Inventory />} />
      <Route path="sale-detail" element={<SaleDetailsPage />} />
    </Routes>
  );
}

export default MemberRoutes;
