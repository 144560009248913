import React, { useEffect, useState } from "react";
import PageHeader from "../../components/common/PageHeader";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllDcr,
  getDcrByReportingManager,
} from "../../api/dcr/dcr-api";

import { setDcr } from "../../redux/features/dcrSlice";

import Loading from "../../components/UI/Loading";
import Swal from "sweetalert2";

import OurDcr from "../../components/Dcr/OurDcr";
import { getAllDesignation } from "../../api/member/member-api";
import {
  setAllDesignation,
  setAllState,
} from "../../redux/features/dropdownFieldSlice";
import { getState } from "../../api/clients/clients-api";
import { getDateFormat } from "../../helper/date-functions";
import { permissionIds } from "../../constants/constants";

function DCR() {
  const [loading, setLoading] = useState(false);
  const Dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [length, setLength] = useState(0);
  
  const Cred = useSelector((state) => state.Cred);
  const Dcr = useSelector((state) => state.Dcr);
  const DropDownsField = useSelector((state) => state.DropDownsField);
  const { memberPermissions } = useSelector((state) => state.Permission);
  
  const uniqueOptions = [];
  const [filteredData, setFilteredData] = useState(Dcr);

  Dcr.allDcr.forEach((item) => {
    const value = item.members.employeeId;
    if (!uniqueOptions.some((option) => option.value === value)) {
      const obj = { label: value, value: value };
      uniqueOptions.push(obj);
    }
  });

  useEffect(() => {
    setFilteredData(Dcr.allDcr);
  }, [Dcr.allDcr]);

  function filterDataFunction(value) {
    const result = Dcr.allDcr.filter(
      (item) => item.members.employeeId === value
    );
    setFilteredData(result);
  }

  async function getAll(regionId) {
    setLoading(true);
    try {
      if (DropDownsField.allState.length <= 0) {
        const stateArray = await getState(Cred.token, regionId);
        Dispatch(setAllState(stateArray));
      }
      if (DropDownsField.allDesignation.length <= 0) {
        const DesignationArray = await getAllDesignation(Cred.token);
        Dispatch(setAllDesignation(DesignationArray.data));
      }
      if (Dcr.allDcr.length <= 0) {
        const currentDate = new Date().toLocaleDateString()
        const resp = memberPermissions.some(
          (item) => item === permissionIds.MANAGER
        )
          ? await getAllDcr(Cred.token, 0, currentDate)
          : await getDcrByReportingManager(Cred.token, 0, Cred.sub);
        Dispatch(
          setDcr({
            paginationData: resp.paginationData,
            allDcr: resp.data.reverse(),
          })
        );
      }
    } catch (error) {
      Swal.fire({
        title: "Something went wrong!",
        text: "Can't Fetch Dcr. Please try After Some Time",
        icon: "error",
      });
    }
    setLoading(false);
  }
  useEffect(() => {
    setPage(0);
    setLength(Dcr.length);
    getAll(Cred.region);
  }, []);

  return (
    <>
      {loading ? (
        <Loading animation={"border"} color={"black"} />
      ) : (
        <div className="container-xxl">
          <PageHeader
            headerTitle="Daily Call Report"
            renderRight={() => {
              return (
                <div className="col-auto d-flex ">
                  {/* <Button
                    variant="primary"
                    onClick={async () => {
                      try {
                        setIsModal(true);
                      } catch (error) {
                        setIsModal(false);
                        Swal.fire(
                          "Something went wrong",
                          "Can't Fetch Necessary data"
                        );
                      } finally {
                        setButtonLoader({
                          ...buttonLoader,
                        });
                        setClicked(false);
                      }
                    }}
                    className="btn btn-primary"
                    disabled={buttonLoader.clicked}
                  >
                    {buttonLoader.clicked ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="Dcr"
                        aria-hidden="true"
                        className="me-1"
                      />
                    ) : (
                      <i className="icofont-plus-circle me-2 fs-6"></i>
                    )}
                    Add
                  </Button> */}
                  {/* <button
                    className="btn btn-dark btn-set-task w-sm-100 mx-2"
                    onClick={async () => {
                      try {
                        // let mainData = [];
                        // Dcr.allDcr.map((item, index) => {
                        //   mainData.push({
                        //     "EMPLOYEE NAME": item.employeeName,
                        //     "LEAVE TYPE":
                        //       item.leaveType === 1
                        //         ? "Flexible leave"
                        //         : item.leaveType === 2
                        //         ? "Unpaid leave"
                        //         : "Sick leave",
                        //     FROM: item.startAt,
                        //     TO: item.endAt,
                        //     REASON: item.reason,
                        //     ACTION:
                        //       item.Dcr === 0
                        //         ? "Pending"
                        //         : item.Dcr === 1
                        //         ? "Approved"
                        //         : "Rejected",
                        //   });
                        // });
                        // exportToExcel(mainData);
                      } catch (error) {
                        Swal.fire(
                          "Something went wrong",
                          "Can't Export Necessary Data",
                          "error"
                        );
                      }
                    }}
                  >
                    <img
                      src="reply-solid.svg"
                      className="mx-2"
                      style={{ transform: "scaleX(-1)", height: "18px" }}
                    />
                    Export
                  </button> */}
                </div>
              );
            }}
          />
          <div className="row clearfix g-3">
            <div className="col-auto d-flex flex-wrap"></div>
            {Dcr.allDcr.length > 0 ? (
              <div className="col-sm-12">
                <OurDcr
                  data={Dcr.allDcr.filter(
                    (e) => e.dcrDate === getDateFormat(new Date().toJSON())
                  )}
                  // data={Dcr.allDcr.filter(
                  //   (e) => e.dcrDate === "2024-03-26"
                  // )}
                />
              </div>
            ) : (
              <div className="background-color: #3498db; color: #fff; text-align: center; padding: 10px;">
                <p className="font-size: 18px; font-weight: bold;">
                  No Dcr To Load.
                </p>
              </div>
            )}
          </div>
        </div>
      )}{" "}
    </>
  );
}

export default DCR;
