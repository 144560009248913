import React, { memo, useState } from "react";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

export const ReportIdType = [
  { label: "Member", value: "member" },
  { label: "ClientFMCG", value: "client" },
];

export const ReportCallType = [
  { label: "By Productive Status", value: "productive" },
  { label: "By Order Medium", value: "order" },
];

export const ReportType = [
  { label: "Beet", value: "beet" },
  { label: "Outlet", value: "outlet" },
];

export const orderCallStatus = [
  { label: "Productive", value: "Productive" },
  { label: "Non Productive", value: "NonProductive" },
];

export const orderMedium = [
  { label: "On Site", value: "OnSite" },
  { label: "On Call", value: "OnCall" },
];

const FilterComponent = ({
  selectedReportIdLevel,
  selectedReportCallLevel,
  selectedReportTypeLevel,
  setSelectedReportTypeLevel,
  setSelectedReportIdLevel,
  setSelectedReportCallLevel,
  getReportData,
}) => {
  const [filterType, setFilterType] = useState(null);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
  const [selectedOrderMedium, setSelectedOrderMedium] = useState(null);
  const [selectedMemberClientId, setSelectedMemberClientId] = useState(null);

  const memberIds = useSelector((state) => state.Member.allMembers);
  const clientIds = useSelector((state) => state.ClientFMCG.allClients);

  const handleReportTypeToggle = () => {
    setFilterType(filterType === "reportType" ? null : "reportType");
  };

  const handleReportIdToggle = () => {
    setFilterType(filterType === "reportId" ? null : "reportId");
  };

  const handleReportCallToggle = () => {
    setFilterType(filterType === "reportCall" ? null : "reportCall");
  };

  const handleConfirmReportChange = () => {
    if (
      !selectedReportTypeLevel ||
      !selectedReportIdLevel ||
      !selectedReportCallLevel ||
      // !selectedOrderStatus ||
      // !selectedOrderMedium ||
      !selectedMemberClientId
    ) {
      Swal.fire({
        title: "Invalid Details",
        text: "Make Sure You Select Each Details",
        timer: 2000,
        icon: "warning",
      });
    }
    console.log("Filters applied:", {
      reportType: selectedReportTypeLevel,
      reportId: selectedReportIdLevel,
      reportCall: selectedReportCallLevel,
      orderStatus: selectedOrderStatus,
      orderMedium: selectedOrderMedium,
      memberClientId: selectedMemberClientId,
    });
    getReportData({
      reportType: selectedReportTypeLevel,
      reportId: selectedReportIdLevel,
      reportCall: selectedReportCallLevel,
      orderStatus: selectedOrderStatus,
      orderMedium: selectedOrderMedium,
      memberClientId: selectedMemberClientId,
    });
    setFilterType(null);
  };

  const handleCancelReportChange = () => {
    setFilterType(null);
    setSelectedOrderStatus(null);
    setSelectedOrderMedium(null);
    setSelectedMemberClientId(null);
  };

  return (
    <div className="filter-component p-3">
      <div className="filter-buttons">
        <Button
          variant={
            filterType === "reportType"
              ? "outline-primary"
              : "outline-secondary"
          }
          onClick={handleReportTypeToggle}
          className="mr-2 custom-btn"
        >
          Select Report Type
        </Button>
        <Button
          variant={
            filterType === "reportId" ? "outline-primary" : "outline-secondary"
          }
          onClick={handleReportIdToggle}
          className="mr-2 custom-btn"
        >
          Select Report Id Type
        </Button>
        <Button
          variant={
            filterType === "reportCall"
              ? "outline-primary"
              : "outline-secondary"
          }
          onClick={handleReportCallToggle}
          className="mr-2 custom-btn"
        >
          Select Report Call Type
        </Button>
      </div>

      {filterType === "reportType" && (
        <div className="mt-3">
          <Select
            options={ReportType}
            value={selectedReportTypeLevel || null}
            onChange={setSelectedReportTypeLevel}
            placeholder="Select Report Type Level"
          />
        </div>
      )}
      {filterType === "reportId" && (
        <div className="mt-3">
          <Select
            options={ReportIdType}
            value={selectedReportIdLevel || null}
            onChange={setSelectedReportIdLevel}
            placeholder="Select Report Id Level"
          />
          {selectedReportIdLevel?.value === "member" && (
            <div className="mt-3">
              <Select
                options={memberIds.map((id) => ({
                  label: `${id.firstName} ${id.lastName} (${id.employeeId})`,
                  value: id.id,
                }))}
                value={selectedMemberClientId || null}
                onChange={setSelectedMemberClientId}
                placeholder="Select Member ID"
              />
            </div>
          )}
          {selectedReportIdLevel?.value === "client" && (
            <div className="mt-3">
              <Select
                options={clientIds.map((id) => ({
                  label: `${id.clientFirstName} ${id.clientLastName} (${id.clientCode})`,
                  value: id.id,
                }))}
                value={selectedMemberClientId || null}
                onChange={setSelectedMemberClientId}
                placeholder="Select Client ID"
              />
            </div>
          )}
        </div>
      )}
      {filterType === "reportCall" && (
        <div className="mt-3">
          <Select
            options={ReportCallType}
            value={selectedReportCallLevel || null}
            onChange={setSelectedReportCallLevel}
            placeholder="Select Report Call Level"
          />
          {selectedReportCallLevel?.value === "productive" && (
            <div className="mt-3">
              <Select
                options={orderCallStatus}
                value={selectedOrderStatus || null}
                onChange={setSelectedOrderStatus}
                placeholder="Select Productive Type"
              />
            </div>
          )}
          {selectedReportCallLevel?.value === "order" && (
            <div className="mt-3">
              <Select
                options={orderMedium}
                value={selectedOrderMedium || null}
                onChange={setSelectedOrderMedium}
                placeholder="Select Order Medium"
              />
            </div>
          )}
          {selectedReportCallLevel && selectedOrderStatus && (
            <div className="button-group mt-3">
              <Button
                variant="secondary"
                className="me-2"
                onClick={handleCancelReportChange}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={handleConfirmReportChange}>
                Confirm
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(FilterComponent);
