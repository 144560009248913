export const MasterMenu = [
  {
    name: "Master",
    routerLink: [""],
    identifier: "Master",
    iconClass: "icofont-ui-settings",
    breadcrumbMessage: "",
    isCategory: false,
    isApp: true,
    children: [
      {
        name: "Region Master",
        routerLink: ["regionMaster"],
        identifier: "Region Master",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
      {
        name: "State Master",
        routerLink: ["stateMaster"],
        identifier: "State Master",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
      {
        name: "City Master",
        routerLink: ["cityMaster"],
        identifier: "City Master",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
    ],
  },
  {
    name: "Reports",
    routerLink: [""],
    identifier: "Reporting",
    iconClass: "icofont-clip-board",
    breadcrumbMessage: "",
    isCategory: false,
    isApp: true,
    children: [
      {
        name: "Sales Report",
        routerLink: ["sales-report"],
        identifier: "Sales Report",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
      // {
      //   name: "Outlet Order Report",
      //   routerLink: ["outlet-order-report"],
      //   identifier: "Outlet Order Report",
      //   iconClass: "",
      //   breadcrumbMessage: "",
      //   isCategory: false,
      //   isApp: false,
      //   children: [],
      // },
      {
        name: "All Order Report",
        routerLink: ["all-order-report"],
        identifier: "All Order Report",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
      {
        name: "Time Invterval Reports",
        routerLink: ["time-interval-report"],
        identifier: "Outlet Order Report",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
    ],
  },
  {
    name: "Sales",
    routerLink: [""],
    identifier: "Sales",
    iconClass: "icofont-chart-histogram",
    breadcrumbMessage: "",
    isCategory: false,
    isApp: true,
    children: [
      {
        name: "Primary Sales",
        routerLink: ["primarySales"],
        identifier: "Primary Sales",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
      {
        name: "Secondary Sales",
        routerLink: ["secondarySales"],
        identifier: "Secondary Sales",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
    ],
  },
  {
    name: "Inventory",
    routerLink: [""],
    identifier: "Products",
    iconClass: "icofont-box",
    breadcrumbMessage: "",
    isCategory: false,
    isApp: true,
    children: [
      {
        name: "Inventory",
        routerLink: ["inventory"],
        identifier: "Inventory",
        iconClass: "",
        breadcrumbMessage: "",
        isCategory: false,
        isApp: false,
        children: [],
      },
    ],
  },
];
