import React, { useEffect, useState } from "react";
import {loginClientFMCG,loginMember} from "../../api/login/login-api";

import {
  setCredentials,
  deleteCredentials,
  addCredentials,
} from "../../redux/features/credentialSlice";

import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { constants } from "../../constants/constants";
import { Spinner } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
function SignIn() {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const Dispatch = useDispatch();

  const [passText, setPassText] = useState("password");

  async function handleSubmit() {
    if (userId && password) {
      setLoading(true);
      if (isNaN(Number(userId))) {
        // client login
        try {
          const resp = await loginClientFMCG(userId, password);
          const decode = await jwtDecode(resp.token);
          await window.localStorage.setItem(constants.token_store, resp.token);
          await window.localStorage.setItem("isMember", false);
          Dispatch(setCredentials({ token: resp.token, ...decode }));
        } catch (error) {
          const errCode = error.response ? error.response.status : undefined;
          if (errCode === 404) {
            Swal.fire({
              title: "Invalid Credentials",
              text: `Invalid UserId or Password. Please Enter  Correct Credentials`,
              icon: "error",
              confirmButtonText: "ok",
            });
          } else {
            Swal.fire({
              title: "Can't SignIn!",
              text: `Something went wrong. Make Sure you have entered correct values to the respective field or Try Again After later`,
              icon: "error",
              confirmButtonText: "ok",
            });
          }
        }


      } else {
        // member login
        try {
          const resp = await loginMember(userId, password);
          const decode = await jwtDecode(resp.token);
          await window.localStorage.setItem(constants.token_store, resp.token);
          await window.localStorage.setItem("isMember", true);
          Dispatch(setCredentials({ token: resp.token, ...decode, isMember: true }));
        } catch (error) {
          const errCode = error.response ? error.response.status : undefined;
          if (errCode === 404) {
            Swal.fire({
              title: "Invalid Credentials",
              text: `Invalid UserId or Password. Please Enter  Correct Credentials`,
              icon: "error",
              confirmButtonText: "ok",
            });
          } else {
            Swal.fire({
              title: "Can't SignIn!",
              text: `Something went wrong. Make Sure you have entered correct values to the respective field or Try Again After later`,
              icon: "error",
              confirmButtonText: "ok",
            });
          }
        }
      }

      setLoading(false);
    } else {
      Swal.fire({
        title: "Incomplete!",
        text: "Please fill the required details",
        icon: "error",
        confirmButtonText: "ok",
      });
    }
  }

  return (
    <div className="col-lg-6 d-flex justify-content-center align-items-center border-0 rounded-lg auth-h100">
      <div
        className="w-100 p-3 p-md-5 card border-0 bg-dark text-light"
        style={{ maxWidth: "32rem" }}
      >
        <form className="row g-1 p-3 p-md-4">
          <div className="col-12 text-center mb-1 mb-lg-5">
            <h1>Sign in</h1>
            <span>Access to Your dashboard.</span>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <label htmlFor="userId" className="form-label">
                User Id
              </label>
              <input
                maxLength={10}
                id="userId"
                type="email"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
                className="form-control form-control-lg"
                placeholder="Email or Phone Number"
              />
            </div>
          </div>
          <div className="col-12">
            <div className="mb-2">
              <div className="form-label">
                <span
                  htmlFor="passwordInput"
                  className="d-flex justify-content-between align-items-center"
                >
                  Password
                  {/* <Link className="text-secondary" to="password-reset">Forgot Password?</Link> */}
                </span>
              </div>

              <div className="d-flex justify-content-between align-items-center form-control form-control-lg">
                <input
                  value={password}
                  id="passwordInput"
                  onChange={(e) => setPassword(e.target.value)}
                  type={passText}
                  className="form-control"
                  style={{ borderStyle: "none", boxShadow: "none" }}
                  placeholder="***************"
                  maxLength={240}
                />
                <div
                  className="fs-4 "
                  onClick={() =>
                    setPassText((prev) =>
                      prev === "password" ? "text" : "password"
                    )
                  }
                >
                  {passText === "password" ? (
                    <i className="icofont-eye-blocked"></i>
                  ) : (
                    <i className="icofont-eye"></i>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-12">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label className="form-check-label" for="flexCheckDefault">
                                    Remember me
                                </label>
                            </div>
                        </div> */}
          {!loading && (
            <div onClick={handleSubmit} className="col-12 text-center mt-4">
              <p
                className="btn btn-lg btn-block btn-light lift text-uppercase"
                atl="signin"
              >
                SIGN IN
              </p>
            </div>
          )}
          {loading && (
            <div className="col-12 text-center mt-4">
              <Spinner
                className="sigin-in-loader"
                animation="border"
                color="black"
                size="sm"
              />
            </div>
          )}
          {/* <div className="col-12 text-center mt-4">
                            <span className="text-muted">Don't have an account yet? <Link to="sign-up" className="text-secondary">Sign up here</Link></span>
                        </div> */}
        </form>
      </div>
    </div>
  );
}
export default SignIn;
