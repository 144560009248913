export const scheduleEnums = {
     "ACTIVE" : 1,
    "INAVTIVE" : 2,
    "PENDING" : 3,
    "DELETE" : 4
}

export const CLIENT_PERMISSIONS = [
    { value: "Create_Manager", label: "Create"},
    { value: "Edit_Manager", label: "Edit"},
    { value: "Delete_Manager", label: "Delete"},
    { value: "Manager", label: "Manager (All Permissions)"},
    { value: "View_Manager", label: "View"},
    { value: "Client", label: "Client"},
]