import * as XLSX from "xlsx";

export const exportToExcel = (data) => {
  if (!Array.isArray(data) || data.length <= 0) {
    return console.log("Something went wrong");
  }
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Leave-Request");

  const fileName = "leaveRequest_data.xlsx";
  XLSX.writeFile(wb, fileName);
};

export function truncateString(str = "", maxLength = 20) {
  if (!str) return;

  if (str.length <= maxLength) {
    return str;
  }
  return str.substring(0, maxLength) + "...";
}
