import React, { useCallback, useEffect, useState } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import PageHeader from "../../components/common/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { getAllAttendance, getMemberAttendance } from "../../api/attendance/attendance-api";
import {
  removeAllAttendance,
  setAttendance,
} from "../../redux/features/attendanceSlice";
import Swal from "sweetalert2";
import Loading from "../../components/UI/Loading";
import DataTable from "react-data-table-component";
import { getDaysInMonth } from "../../helper/date-functions";
import AsyncSelect from "react-select/async";
import { deleteAllMembers, setMembers } from "../../redux/features/memberSlice";
import { getAllMembers } from "../../api/member/member-api";
import { permissionIds } from "../../constants/constants";

function Attendance() {
  const Cred = useSelector((state) => state.Cred);
  const Attendance = useSelector((state) => state.Attendance);
  const Member = useSelector((state) => state.Member.allMembers);
  const MemberPermission = useSelector(
    (state) => state.Permission.memberPermissions
  );

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [loading, setLoading] = useState(false);
  const [yearData, setYearData] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const Dispatch = useDispatch();

  const filterMember = (inputValue) => {
    return Member.filter(
      (i) =>
        i.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
        i.lastName.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = async (inputValue) => {
    const value = await new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterMember(inputValue));
      }, 1000);
    });
    let options = value.map((item) => ({
      label: `${item.firstName} ${item.lastName} (${item.employeeId})`,
      value: {
        firstName: item.firstName,
        lastName: item.lastName,
        employeeId: item.id
      },
    }));
    return options;
  };

  const MonthsArray = [
    { id: 1, month: "January", days: 31 },
    { id: 2, month: "February", days: 28 },
    { id: 3, month: "March", days: 31 },
    { id: 4, month: "April", days: 30 },
    { id: 5, month: "May", days: 31 },
    { id: 6, month: "June", days: 30 },
    { id: 7, month: "July", days: 31 },
    { id: 8, month: "August", days: 31 },
    { id: 9, month: "September", days: 30 },
    { id: 10, month: "October", days: 31 },
    { id: 11, month: "November", days: 30 },
    { id: 12, month: "December", days: 31 },
  ];

  const currentDate = new Date();

  const generateTableColumns = useCallback(() => {
    let columns = [];

    // Add Member Name column
    columns.push({
      name: "Member Name",
      selector: (row) => row.memberName,
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "nowrap" }}>{row.memberName}</div>
      ),
      style: {
        backgroundColor: "#b6e8e3",
        position: "sticky",
        top: "0",
        left: "0",
        zIndex: "2",
      },
    });

    // Get the number of days in the current month
    const monthObj = MonthsArray.find((m) => m.id == currentMonth);
    const daysInMonth = getDaysInMonth(currentMonth);

    // Generate columns for each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
      columns.push({
        name: `${day}`,
        cell: (row) => {
          const status = getAttendanceStatus(row.memberAttendance, day);
          return (
            <span
              style={{
                color:
                  status === "Marked"
                    ? "green"
                    : status === "Absent"
                    ? "red"
                    : "black",
                display: "block",
                textAlign: "center",
              }}
            >
              {status}
            </span>
          );
        },
      });
    }

    return columns;
  }, [currentMonth, currentYear, selectedMember]);

  //get years Dropdown Data

  function generateYearData() {
    const myYearData = [];
    let nowYear = new Date().getFullYear();

    for (let i = nowYear; i > nowYear-5; i--) {
      myYearData.push(i);
    }
    setYearData(myYearData);
  }

  // Function to determine attendance status for a given day
  function getAttendanceStatus(attendanceArray, day) {
    const attendanceForDay = attendanceArray.find(
      (entry) => Number(entry.checkInDay) === day
    );

    const today = currentDate.getDate();
    const isFutureDay = day > today;

    if (attendanceForDay) {
      return <i className="icofont-check-circled text-success"></i>; // Check-in exists for the day
    } else if (day < today) {
      return <i className="icofont-close-circled text-danger"></i>; // No check-in for a past day
    } else if (isFutureDay) {
      return "-"; // Future day
    }
  }

  // Organize attendance data
  function organiseAttendance(response) {
    let myArray = [];

    response.forEach((item) => {
      let result = myArray.find((val) => val.id === item.memberId);
      const checkInDay = item?.checkIn?.split("T")[0].split("-")[2] || null;
      const checkOutDay = item?.checkOut?.split("T")[0].split("-")[2] || null;
      const memberName = `${item.firstName ? item.firstName : ""} ${
        item.lastName ? item.lastName : ""
      }`;

      const attendanceEntry = {
        checkInDay,
        checkOutDay,
        memberName,
      };

      if (result) {
        result.memberAttendance.push(attendanceEntry);
      } else {
        let newMember = {
          id: item.memberId,
          memberName,
          data: item,
          memberAttendance: [attendanceEntry],
        };
        myArray.push(newMember);
      }
    });

    return myArray;
  }

  const getEmployeeAttendance = async (value= selectedMember?.value) => {
    const month = Number(currentMonth);
    const year = Number(currentYear);

    try {
      if (value) {

        const firstname = value.firstName.toUpperCase()
        const lastName = value.lastName.toUpperCase()
        const memberId = value.employeeId
        Dispatch(removeAllAttendance());
        const resp = await getMemberAttendance(Cred.token, Cred.sub, month, year, memberId, firstname, lastName);
        const result = organiseAttendance(resp.content);
        Dispatch(setAttendance({ ...resp, content: result }));
        generateYearData();
      } else {
        Dispatch(removeAllAttendance());
        const resp = await getAllAttendance(Cred.token, Cred.sub, month, year);
        const result = organiseAttendance(resp.content);
        Dispatch(setAttendance({ ...resp, content: result }));
        generateYearData();
      }
    } catch (error) {
      console.log("Error fetching attendance :", error);
      if (yearData) {
        Swal.fire(
          "No Attendance Found",
          "Please Select Another Time period",
          "info"
        );
      } else {
        Swal.fire(
          "Something Went Wrong",
          "Please Try After Some Time",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    getEmployeeAttendance();
  }, [currentMonth, currentYear, selectedMember]);

  const columnT = generateTableColumns();

  async function get() {
    if (
      MemberPermission.some(
        (item) => item == permissionIds.MANAGER && item == permissionIds.VIEW_MANAGER
      )
    ) {
      try {
        if (Member.length <= 0) {
          Dispatch(deleteAllMembers());
          const MembersArrays = await getAllMembers(0, Cred.token, Cred.sub);
          if (MembersArrays.data.length >= 0) {
            Dispatch(
              setMembers({
                allMembers: MembersArrays.data,
                paginationData: MembersArrays.paginationData,
              })
            );
          } else {
          }
        }
      } catch (error) {
        Swal.fire({
          title: "Something went wrong!",
          text: "Can't Fetch Employees. Please try After Some Time",
          icon: "error",
        });
        console.log("error: ", error);
      }
    }
  }
  useEffect(() => {
    generateYearData()
    if (Member.length <= 0) {
      get();
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loading animation={"border"} color={"black"} />
      ) : (
        <div className="container-xxl">
          <PageHeader headerTitle="Attendance (Admin)" />
          <div className="row clearfix g-3">
            <div className="modal-body">
              <div className="deadline-form">
                <button
                  className="btn btn-primary ms-4"
                  onClick={() => {
                    setSelectedMember(null);
                    getEmployeeAttendance();
                  }}
                >
                  View All Attendance
                </button>
                <form>
                  <div className="m-3 row">
                    <div className="col-lg-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlInput478"
                      >
                        Filter by Year
                      </label>
                      <select
                        className="form-select"
                        id="exampleFormControlInput478"
                        value={currentYear}
                        onChange={(e) => setCurrentYear(e.target.value)}
                      >
                        <option value="">Select a Year</option>
                        {yearData.map((value, i) => {
                          return (
                            <option value={value} key={i}>
                              {value}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-lg-3">
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlInput578"
                      >
                        Filter by Month
                      </label>
                      <select
                        className="form-select"
                        id="exampleFormControlInput578"
                        value={
                          MonthsArray.find((item) => item.id === currentMonth)
                            ?.month
                        }
                        onChange={async (e) => setCurrentMonth(e.target.value)}
                      >
                        <option value="">Select a Month</option>
                        {MonthsArray.map((value, i) => {
                          return (
                            <option value={value.id} key={i}>
                              {value.month}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="col-lg-3" style={{ zIndex: 10 }}>
                      <label
                        className="form-label"
                        htmlFor="exampleFormControlInput478"
                      >
                        Search by name
                      </label>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={promiseOptions}
                        value={selectedMember}
                        onChange={(e) => {
                          setSelectedMember(e);
                          console.log(e);
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <table>
            <div className="col-sm-10">
              {Attendance?.content?.length > 0 ? (
                <DataTable
                  id="Data_table"
                  columns={columnT.map((column) => ({
                    ...column,
                    width: `${column.name.length * 10 + 30}px`,
                    wrap: true,
                    position: "relative",
                  }))}
                  title={"Attendance"}
                  data={Attendance.content}
                  pagination
                  selectableRows={false}
                  className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                  highlightOnHover={true}
                  paginationServer
                  paginationTotalRows={Attendance.totalElements}
                />
              ) : (
                <h1>No Attendance Marked</h1>
              )}  
            </div>
            </table>
          </div>
        </div>
      )}
    </>
  );
}

export default Attendance;
