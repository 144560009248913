export const API_URL = {
  backend_url: "https://api.prism-sfa-dev.net/",
};

export const REGION_MASTER = {
  GETALL_ENDPOINT: "allRegions",
  GETID_ENDPOINT: "getRegionById",
  POST_ENDPOINT: "createRegionList",
  DELETE_ENDPOINT: "deleteRegionById",
  PUT_ENDPOINT: "updateRegionById",
};

export const STATE_MASTER = {
  GETALL_ENDPOINT: "states/allStates",
  GETID_ENDPOINT: "states/getStateById",
  GET_STATES_BY_REGION_ID_ENDPOINT: "states/getStateById",
  POST_ENDPOINT: "states/createStateList",
  DELETE_ENDPOINT: "states/deleteStateById",
  PUT_ENDPOINT: "states/updateStateById",
};

export const CITY_MASTER = {
  GETALL_ENDPOINT: "allCities",
  GETID_ENDPOINT: "getCityById",
  GET_STATES_BY_REGION_ID_ENDPOINT: "getCityByStateId",
  POST_ENDPOINT: "createCityList",
  PUT_ENDPOINT: "updateCityById",
  DELETE_ENDPOINT: "deleteCityById",
};

// export const ORDER_URL = {
//   GETALL_ENDPOINT: "allCities",
//   GETID_ENDPOINT: "getCityById",
//   GET_STATES_BY_REGION_ID_ENDPOINT: "getCityByStateId",
//   POST_ENDPOINT: "createCityList",
//   DELETE_ENDPOINT: "updateCityById",
//   PUT_ENDPOINT: "deleteCityById",
// };

export const CLIENT_FMCG_URL = {
  GETALL_ENDPOINT: "client-fmcg/getAllClientFmcg",
  GETID_ENDPOINT: "client-fmcg/getClientFMCGById",
  POST_ENDPOINT: "client-fmcg/clientFmcg",
  DELETE_ENDPOINT: "client-fmcg/deleteClientFmcg",
  PUT_ENDPOINT: "client-fmcg/updateClientFMCGById",
};
