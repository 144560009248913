import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllInventoryWithProductAndClientFilter } from "../../api/inventory/inventory-api";
import {
  concatInventory,
  setInventory,
} from "../../redux/features/inventorySlice";
import Swal from "sweetalert2";

// -1 no-loader
// 0 paginationTableCall 1 for next page

function useInventory() {
  const { paginationData, allInventory } = useSelector(
    (state) => state.Inventory
  );
  const [loading, setLoading] = useState(-1);
  const dispatch = useDispatch();
  const isMember = JSON.parse(window.localStorage.getItem("isMember"));
  const Cred = useSelector((state) => state.Cred);
  async function helperFunctionSales(page) {
    if (allInventory.length > 0 && page == 0) return;
    setLoading(page == 0 ? 1 : 0);
    try {
      const resp = await getAllInventoryWithProductAndClientFilter(
        isMember ? 0 : Cred.sub,
        page,
        10,
        Cred.token
      );
      dispatch(
        page === 0
          ? dispatch(
              setInventory({
                allInventory: resp.content,
                paginationData: {
                  page: resp.page,
                  totalPages: resp.totalPages,
                  totalElements: resp.totalElements,
                },
              })
            )
          : dispatch(
              concatInventory({
                allInventory: resp.content,
                paginationData: {
                  page: resp.page,
                  totalPages: resp.totalPages,
                  totalElements: resp.totalElements,
                },
              })
            )
      );
    } catch (error) {
      Swal.fire({
        title: "Something went wrong!",
        text: "Can't Fetch Orders. Please try After Some Time",
        icon: "error",
      });
    }
    setLoading(-1);
  }

  useEffect(() => {
    helperFunctionSales(0);
  }, []);
  const table_columns = useMemo(
    () => [
      {
        name: "S.No",
        selector: (row, index) => index + 1,
        sortable: true,
      },
      {
        name: "Product Name",
        selector: (row) => row.productRes.name,
        sortable: true,
      },
      {
        name: "Quantity",
        selector: (row) => row.quantity,
        sortable: true,
      },
      {
        name: "Sales Level",
        selector: (row) => row.salesLevel,
        sortable: true,
      },
    ],
    [allInventory]
  );

  return {
    paginationData,
    allInventory,
    helperFunctionSales,
    loading,
    table_columns
  };
}

export default useInventory;
