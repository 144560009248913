import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useIsManager = () => {
  const Permission = useSelector((state) => state.Permission.memberPermissions);
  const isManager = useMemo(
    () => Permission.find((item) => item?.includes("Manager")),
    [Permission]
  );
  return isManager;
};

export const useIsClient = () => {
  const Cred = useSelector((state) => state.Cred);
  return !!Cred?.clientCode;
};
