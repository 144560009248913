import React, { useEffect } from "react";
import PageHeader from "../../../components/common/PageHeader";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import * as XLSX from "xlsx";
import useAllOrdersReportHook from "./useAllOrdersReportHook";
import FilterComponent from "./FilterComponent";
import { useMemberHook } from "../../../hooks/common-function";
import { useClientFMCGHook } from "../../../hooks/clientFMCGHook";
import { useSelector } from "react-redux";

function SalesReport() {
  const {
    loading,
    allData,
    columns,
    selectedReportTypeLevel,
    selectedReportIdLevel,
    selectedReportCallLevel,
    setSelectedReportTypeLevel,
    setSelectedReportIdLevel,
    setSelectedReportCallLevel,
    getReportData
  } = useAllOrdersReportHook();

  const { allMembers, paginationData } = useSelector((state) => state.Member)
  const {
    get
  } = useMemberHook()
  const {
    getClientFMCG
  } = useClientFMCGHook()

  useEffect(() => {
    get();
    getClientFMCG();
  })

  const exportToExcel = () => {
    // Dynamically define headers based on selectedReportTypeLevel
    const headers =
      selectedReportTypeLevel === "beet"
        ? [
            "Beet",
            "Address",
            "Postal Code",
            "State",
            "City",
            "Total Sales",
            "Total Orders",
          ]
        : [
            "Outlet Name",
            "Outlet Type",
            "Owner Name",
            "Total Sales",
            "Total Orders",
          ];

    const rows = allData.content.map((row) => {
      if (selectedReportTypeLevel === "beet") {
        return [
          row.beetRespForOrderDto.beet,
          row.beetRespForOrderDto.address,
          row.beetRespForOrderDto.postalCode,
          row.beetRespForOrderDto.state,
          row.beetRespForOrderDto.city,
          row.totalSales,
          row.totalOrder,
        ];
      } else {
        return [
          row.outletRespForOrderDto.outletName,
          row.outletRespForOrderDto.outletType,
          row.outletRespForOrderDto.ownerName,
          row.totalSales,
          row.totalOrder,
        ];
      }
    });

    const wsData = [headers, ...rows];
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Set column widths
    ws["!cols"] = headers.map(() => ({ width: 20 }));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Orders Data");
    XLSX.writeFile(wb, "orders_data.xlsx");
  };

  return (
    <div className="container-xxl">
      <PageHeader
        headerTitle="All Order Report"
        renderRight={() => (
          <Button onClick={exportToExcel}>Export To Excel</Button>
        )}
      />
      <FilterComponent
        selectedReportTypeLevel={selectedReportTypeLevel}
        setSelectedReportTypeLevel={setSelectedReportTypeLevel}
        selectedReportIdLevel={selectedReportIdLevel}
        setSelectedReportIdLevel={setSelectedReportIdLevel}
        selectedReportCallLevel={selectedReportCallLevel}
        setSelectedReportCallLevel={setSelectedReportCallLevel}
        getReportData={getReportData}
      />

      <div className="mt-3">
        <DataTable
          title="Sales Data"
          columns={columns}
          data={allData.content}
          responsive
          pagination
          progressPending={loading}
        />
      </div>
    </div>
  );
}

export default SalesReport;
