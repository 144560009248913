import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { permissionIds } from "../../constants/constants";

const ProtectedRoute = ({ element }) => {
  const { memberPermissions } = useSelector((state) => state.Permission);

  const condition = memberPermissions.some(
    (item) => item === permissionIds.MANAGER
  );
  if (!condition) {
    return <Navigate to="/" replace />;
  }

  return element;
};

export default ProtectedRoute;
