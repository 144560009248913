import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import logo from "../../../assets/images/logo.png";
const useSalesDetailInvoice = (orderData, invoiceNumber) => {
  const generateInvoice = () => {
    const doc = new jsPDF();

    doc.setFontSize(24);
    doc.text("Invoice", 14, 20);
    doc.addImage(logo, "PNG", 160, 10, 30, 30);

    doc.setFontSize(12);
    doc.text(`Invoice Number: ${invoiceNumber}`, 14, 40);

    const tableBody = orderData.map((order, index) => {
      const product = order.productRes || {};
      return [
        index + 1,
        product.name || "N/A",
        order.quantity || 0,
        `₹ ${order.totalPriceWithGst?.toFixed(2) || "0.00"}`,
        `₹ ${order.totalPrice?.toFixed(2) || "0.00"}`,
      ];
    });

    autoTable(doc, {
      startY: 50,
      head: [["S.No", "Product Name", "Quantity", "Post GST", "Pre GST"]],
      body: tableBody,
    });

    const totalGst = orderData
      .reduce((acc, order) => acc + (order.gstAmount || 0), 0)
      .toFixed(2);
    const totalAmount = orderData
      .reduce((acc, order) => acc + (order.totalPriceWithGst || 0), 0)
      .toFixed(2);

    doc.setFontSize(10);

    doc.text(
      `GST Applied: ₹ ${totalGst}`,
      14,
      doc.previousAutoTable.finalY + 10
    );
    doc.text(`Total: ₹ ${totalAmount}`, 14, doc.previousAutoTable.finalY + 25);

    doc.text("Thank you!", 14, 280);
    doc.text("Payment Information", 14, 290);
    doc.text("Briard Bank", 14, 295);
    doc.text("Account Name: Samira Hadid", 14, 300);
    doc.text("Account No.: 123-456-7890", 14, 305);
    doc.text("Pay by: 5 July 2025", 14, 310);

    doc.save(`${invoiceNumber}.pdf`);
  };

  return generateInvoice;
};

export default useSalesDetailInvoice;
