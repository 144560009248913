import { createSlice } from "@reduxjs/toolkit";

const initialBeet = {
  content: [],
  totalElements: 0,
  totalPages: 0,
  page: 0,
};

export const BeetSlice = createSlice({
  name: "Beet",
  initialState: initialBeet,
  reducers: {
    setBeets: (state, action) => {
      state.content = [...action.payload.content];
      state.totalElements = action.payload.totalElements;
      state.totalPages = action.payload.totalPages;
      state.page = action.payload.page;
    },
    addSingleBeet: (state, action) => {
      state.content = [action.payload, ...state.content]; 
      state.totalElements += 1;
    },
    addOutletToBeet: (state, action) => {
      const { id, data } = action.payload;
      const beet = state.content.find((b) => b.id === data.beet.id);
      if (beet) {
        beet.outlets = [...(beet.outlets || []), data];
      }
    },
  },
});

export const { addSingleBeet, setBeets, addOutletToBeet } = BeetSlice.actions;

export default BeetSlice.reducer;
