import { useEffect, useMemo, useState } from "react";
import {
  getBeetOrderReportByMemberIdApi,
  getBeetOrderReportByReportingManagerApi,
  getOutletOrderReportApi,
} from "../../../api/reports";

const useAllTimeIntervalReportsHook = () => {
  const [allData, setAllData] = useState({
    totalElements: 0,
    totalPages: 0,
    page: 0,
    content: [],
  });
  const [loading, setLoading] = useState(false);

  const [selectedReportTypeLevel, setSelectedReportTypeLevel] = useState(null);
  const [selectedReportIdLevel, setSelectedReportIdLevel] = useState(null);
  const [selectedReportCallLevel, setSelectedReportCallLevel] = useState(null);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
  const [selectedOrderMedium, setSelectedOrderMedium] = useState(null);
  const [selectedMemberClientId, setSelectedMemberClientId] = useState(null);
  const [selectedOutletId, setSelectedOutletId] = useState(null);
  const currentDate = new Date();
  const endDate = new Date(currentDate);
  endDate.setMonth(currentDate.getMonth() + 1);
  const [filterDateRange, setFilteredDateRange] = useState({
    startDate: currentDate,
    endDate: endDate,
    key: "selection",
  });

  const getReportData = async (params) => {
    setLoading(true);
    console.log(params)
    try {
      const reportType = params?.reportType?.value;
      const reportId = params?.reportId?.value;
      const memberClientId = params?.memberClientId?.value;

      let response;

      if (reportType === "beet") {
        if (reportId === "member") {
          response = await getBeetOrderReportByMemberIdApi({
            startDate: filterDateRange.startDate,
            endDate: filterDateRange.endDate,
            memberId: memberClientId,
          });
        } else if (reportId === "reportingManager") {
          response = await getBeetOrderReportByReportingManagerApi({
            startDate: filterDateRange.startDate,
            endDate: filterDateRange.endDate,
            reportingManagerId: memberClientId,
          });
        }
      } else if (reportType === "outlet") {
        response = await getOutletOrderReportApi({
          startDate: filterDateRange.startDate,
          endDate: filterDateRange.endDate,
          beetId: memberClientId,
        });
      }

      if (response) {
        setAllData({
          totalElements: response.totalElements || 0,
          totalPages: response.totalPages || 0,
          page: response.page || 0,
          content: response.content || [],
        });
      }
    } catch (error) {
      console.error("Failed to fetch report data", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => {
    if (selectedReportTypeLevel?.value === "beet") {
      return [
        { name: "S.No", selector: (_, index) => index + 1, sortable: true },
        {
          name: "Beet",
          selector: (row) => row.beetRespForOrderDto?.beet || "N/A",
          sortable: true,
        },
        {
          name: "Address",
          selector: (row) => row.beetRespForOrderDto?.address || "N/A",
          sortable: true,
        },
        {
          name: "Postal Code",
          selector: (row) => row.beetRespForOrderDto?.postalCode || "N/A",
          sortable: true,
        },
        {
          name: "State",
          selector: (row) => row.beetRespForOrderDto?.state || "N/A",
          sortable: true,
        },
        {
          name: "City",
          selector: (row) => row.beetRespForOrderDto?.city || "N/A",
          sortable: true,
        },
        {
          name: "Total Sales",
          selector: (row) => row.totalSales || 0,
          sortable: true,
        },
        {
          name: "Total Orders",
          selector: (row) => row.totalOrder || 0,
          sortable: true,
        },
      ];
    }

    return [
      { name: "S.No", selector: (_, index) => index + 1, sortable: true },
      {
        name: "Outlet Name",
        selector: (row) => row.outletRespForOrderDto?.beet || "N/A",
        sortable: true,
      },
      {
        name: "Address",
        selector: (row) => row.outletRespForOrderDto?.address || "N/A",
        sortable: true,
      },
      {
        name: "Postal Code",
        selector: (row) => row.outletRespForOrderDto?.postalCode || "N/A",
        sortable: true,
      },
      {
        name: "State",
        selector: (row) => row.outletRespForOrderDto?.state || "N/A",
        sortable: true,
      },
      {
        name: "City",
        selector: (row) => row.outletRespForOrderDto?.city || "N/A",
        sortable: true,
      },
      {
        name: "Total Sales",
        selector: (row) => row.totalSales || 0,
        sortable: true,
      },
      {
        name: "Total Orders",
        selector: (row) => row.totalOrder || 0,
        sortable: true,
      },
    ];
  }, [selectedReportTypeLevel]);

  return {
    selectedReportTypeLevel,
    selectedReportIdLevel,
    selectedReportCallLevel,
    selectedOrderStatus,
    selectedOrderMedium,
    selectedMemberClientId,
    setSelectedReportTypeLevel,
    setSelectedReportIdLevel,
    setSelectedReportCallLevel,
    setSelectedOrderStatus,
    setSelectedOrderMedium,
    getReportData,
    setSelectedMemberClientId,
    loading,
    allData,
    columns,
    setSelectedOutletId,
    filterDateRange,
    setFilteredDateRange,
  };
};

export default useAllTimeIntervalReportsHook;
