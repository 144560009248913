export const MemberMenu = [
    {
      name: "Employee",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-users-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Members",
          routerLink: [""],
          identifier: "members",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: []
        },
        {
          name: "Attendance",
          routerLink: ["attendance"],
          identifier: "Attendance",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: []
        },
        {
          name: "Leave Requests",
          routerLink: ["leave-request"],
          identifier: "leave-request",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: []
        },
        {
          name: "Schedules",
          routerLink: ["members-scheduler"],
          identifier: "member scheduler",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: []
        },
        {
          name: "DCR",
          routerLink: ["call-report"],
          identifier: "call report",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: []
        },
        {
          name: "DSR",
          routerLink: ["status-report"],
          identifier: "status report",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: []
        },
        {
          name: "Holidays",
          routerLink: ["holidays"],
          identifier: "holidays",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: []
        }
      ]
    },
    {
      name: "Our Clients",
      routerLink: ["/"],
      identifier: "Members",
      iconClass: "icofont-user-alt-5",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Clients",
          routerLink: ["clients"],
          identifier: "clients",
          iconClass: "icofont-users-alt-5",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: []
        },
        {
          name: "Clients (FMCG)",
          routerLink: ["clientsFmcg"],
          identifier: "clients",
          iconClass: "icofont-users-alt-5",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: []
        },
        {
          name: "Beet",
          routerLink: ["beets"],
          identifier: "beets",
          iconClass: "icofont-location-pin",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: true,
          children: []
        }
      ]
    },
    {
      name: "Accounts",
      routerLink: [""],
      identifier: "Accounts",
      iconClass: "icofont-ui-calculator",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Expenses",
          routerLink: ["expenses"],
          identifier: "Expenses",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: []
        }
      ]
    },
    {
      name: "Products",
      routerLink: [""],
      identifier: "Products",
      iconClass: "icofont-box",
      breadcrumbMessage: "",
      isCategory: false,
      isApp: true,
      children: [
        {
          name: "Product",
          routerLink: ["productmaster"],
          identifier: "Product Master",
          iconClass: "",
          breadcrumbMessage: "",
          isCategory: false,
          isApp: false,
          children: []
        },
        // {
        //   name: "Inventory",
        //   routerLink: ["inventory"],
        //   identifier: "Inventory",
        //   iconClass: "",
        //   breadcrumbMessage: "",
        //   isCategory: false,
        //   isApp: false,
        //   children: []
        // }
      ]
    },
    // {
    //   name: "Sales",
    //   routerLink: [""],
    //   identifier: "Sales",
    //   iconClass: "icofont-chart-histogram",
    //   breadcrumbMessage: "",
    //   isCategory: false,
    //   isApp: true,
    //   children: [
    //     {
    //       name: "Primary Sales",
    //       routerLink: ["primarySales"],
    //       identifier: "Primary Sales",
    //       iconClass: "",
    //       breadcrumbMessage: "",
    //       isCategory: false,
    //       isApp: false,
    //       children: []
    //     },
    //     {
    //       name: "Secondary Sales",
    //       routerLink: ["secondarySales"],
    //       identifier: "Secondary Sales",
    //       iconClass: "",
    //       breadcrumbMessage: "",
    //       isCategory: false,
    //       isApp: false,
    //       children: []
    //     }
    //   ]
    // }
  ];
