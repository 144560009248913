import React, { useMemo, useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import Swal from "sweetalert2";
import { updateOrderApi } from "../../../../api/order/order-api";
import { useDispatch, useSelector } from "react-redux";
import { dispatchPrimarySaleUpdateOrder } from "../../../../redux/features/order/primary-sale";
import { dispatchSecondarySalesUpdateOrder } from "../../../../redux/features/order/secondary-sale";
import { permissionIds } from "../../../../constants/constants";
import { addNewInventoryBulk } from "../../../../api/inventory/inventory-api";

const clientOptions = [
  { value: "CREATED", label: "Created" },
  { value: "CONFIRMED", label: "Confirmed" },
];

const managerOptions = [
  { value: "DISPATCHED", label: "Dispatched" },
  { value: "DELIVERED", label: "Delivered" },
];

function PrimarySaleUpdateModal({
  isVisible,
  setVisible,
  orderData,
  isPrimary,
  noRedux,
  onUpdate,
}) {
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const Cred = useSelector((state) => state.Cred);
  const isMember = window.localStorage.getItem("isMember");
  const Permission = useSelector((state) => state.Permission.memberPermissions);
  const isManger = Permission.find((item) => item?.includes("Manager"));
  const dispatch = useDispatch();
  const handleClose = () => setVisible(false);
  const handleSave = async () => {
    setLoading(true);
    try {
      const updateResp = await updateOrderApi(
        orderData.orderId,
        orderStatus.value,
        Cred.token
      );
      if (orderStatus.value === "DELIVERED") {
        const refinedInventoryList = [
          {
            productId: orderData.productId,
            quantity: orderData.quantity,
            updateAt: "",
            salesLevel: isPrimary ? "WAREHOUSE" : "STOCKIST",
            clientId: orderData.clientId,
          },
        ];
        await addNewInventoryBulk(
          { inventoryRequestList: refinedInventoryList },
          Cred.token
        );
      }
      if (noRedux) {
        onUpdate?.(updateResp);
      } else {
        dispatch(
          isPrimary
            ? dispatchPrimarySaleUpdateOrder(updateResp)
            : dispatchSecondarySalesUpdateOrder(updateResp)
        );
      }

      setVisible(false);
      Swal.fire("Successfully", "Order Updated", "success");
    } catch (error) {
      let orderDataRef = { ...orderData };
      setVisible(null);
      Swal.fire({
        title: "Something Went Wrong",
        text: "Order Can't Be Edited",
        icon: "warning",
      }).finally(() => setVisible(orderDataRef));
      console.log(error);
    }
    setLoading(false);
  };
  return (
    <Modal show={isVisible} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update Primary Sale Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formStatus">
            <Form.Label>Status</Form.Label>
            <Select
              options={
                JSON.parse(isMember)
                  ? isManger
                    ? managerOptions
                    : []
                  : clientOptions
              }
              value={orderStatus}
              isSearchable
              placeholder="Select Status"
              onChange={setOrderStatus}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleSave}
          disabled={loading || orderStatus == null}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />
          ) : orderStatus === null ? (
            "Please Select"
          ) : (
            "Save Changes"
          )}
        </Button>
        <Button variant="secondary" onClick={() => setVisible(null)}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PrimarySaleUpdateModal;
