import DataTable from "react-data-table-component";
import Loading from "../../components/UI/Loading";
import { useSelector } from "react-redux";
import { ExpensesData } from "../../components/Data/AppData";
import  { permissionIds } from "../../constants/constants"
import { useEffect } from "react";

export default function ExpenseTable({allMemberArray, handlePageChange,expenseData, SelectedExpense, setIsEditModal, setIsDeleteModal}){

    const allExpenses = useSelector((state) => state.Expenses)
    const { memberPermissions } = useSelector((state) => state.Permission)

    const getStatusStyles = (status) => {
        if (status === 1) {
          return {
            backgroundColor: "green",
            color: "white",
            padding: 7,
          };
        } else {
          return {
            backgroundColor: "red",
            color: "white",
            padding: 7,
          };
        }
      };

  var columnsT = "";
    columnsT = [
        {
          name: "Member",
          selector: (row) =>
            allMemberArray.find((item) => item.employeeId == row.employeeId)
              ?.employeeName || "NA",
          sortable: true,
        },
        {
          name: "Spent At",
          selector: (row) => row.spentAt || "NA",
          sortable: true,
        },
    
        {
          name: "DATE",
          selector: (row) => row.date || "NA",
          sortable: true,
        },
        {
          name: "Amount (INR)",
          selector: (row) => row.amount ? `₹ ${row.amount}` : "NA",
          sortable: true,
        },
        {
          name: "Remark",
          selector: () => {},
          sortable: true,
          cell: (row) => (
            <div>
              {" "}
              {/* <img className="avatar rounded-circle" src={row.image} alt=""></img> */}
              <span className="fw-bold ms-1">
                {row.remark ? row.remark : "No Remark"}
              </span>
            </div>
          ),
        },
        {
          name: "ACTION",
          selector: () => {},
          sortable: true,
          cell: (row) => {
            return (
              <div
                className="btn-group"
                role="group"
                aria-label="Basic outlined example"
              >
                {row.status == 0 || row.status == null ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => {
                        // acceptExpense(row);
                        SelectedExpense.current = row;
                        setIsEditModal(true);
                      }}
                    >
                      <i className="icofont-check-circled text-success"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-secondary deleterow"
                      onClick={() => {
                        SelectedExpense.current = row;
                        setIsDeleteModal(true);
                      }}
                    >
                      <i className="icofont-close-circled text-danger"></i>
                    </button>
                  </>
                ) : (
                  <p
                    style={{
                      marginTop: "1em",
                      ...getStatusStyles(row.status),
                      fontSize: 13,
                      borderTopLeftRadius: 10,
                      borderBottomRightRadius: 10,
                    }}
                  >
                    {row.status === 1 ? "Accepted" : "Rejected"}
                  </p>
                )}
              </div>
            );
          },
        },
      ];

    // useEffect(()=> {
    //     console.log("Reload Table", expenseData);
    // }, [expenseData])

    return (
        <DataTable
                title={ExpensesData.title}
                columns={columnsT}
                data={allExpenses.allExpenses}
                defaultSortField="title"
                onChangePage={handlePageChange} // Use a proper function for page changes
                pagination
                selectableRows={false}
                className="table myDataTable table-hover align-middle mb-0 d-row nowrap dataTable no-footer dtr-inline"
                highlightOnHover={true}
                page
                paginationServer
                progressComponent={
                  <Loading animation={"border"} color={"black"} />
                }
                paginationTotalRows={allExpenses.paginationData.totalElements}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
              />
    )
}