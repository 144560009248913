import { createSlice } from "@reduxjs/toolkit";

const initialClientFMCG = {
  allClients: [],
  paginationData: { }
};

export const ClientFMCG = createSlice({
  name: "ClientFMCG",
  initialState: initialClientFMCG,
  reducers: {
    setClientsFMCG: (state, action) => {

      state.allClients = [...action.payload.allClients]
      state.paginationData = {...action.payload.paginationData};
    },
    addClientsFMCG: (state, action) => {
      state.allClients = [action.payload, ...state.allClients];
    },
    deleteClientsFMCG: (state, action) => {
      state.allClients = state.allClients.filter((client) => client.id !== action.payload);
    },
    updateClientsFMCG: (state, action) => {
      state.allClients = state.allClients.map((client) =>
        client.id === action.payload.id ? action.payload : client
      );
    },
    deleteAllClientsFMCG: (state, action) => {
      return initialClientFMCG;
    },
    concatClientsFMCG: (state, action) => {
      state.allClients = [...state.allClients, ...action.payload.allClients];
      state.paginationData = {
        ...state.paginationData,
        ...action.payload.paginationData,
      };
    },
  },
});

export const {
  addClientsFMCG,
  concatClientsFMCG,
  deleteAllClientsFMCG,
  deleteClientsFMCG,
  setClientsFMCG,
  updateClientsFMCG,
} = ClientFMCG.actions;

export default ClientFMCG.reducer;
