import { useEffect, useMemo, useState } from "react";
import { getDateFormat } from "../../../helper/date-functions";
import { formatStatus } from "../PrimarySales/sales-hook";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { getAllOrderByInvoiceNumber } from "../../../api/order/order-api";
import { useIsClient } from "../../../helper/isManager";
import useSalesDetailInvoice from "./useSalesDetailInvoice";

export const useSaleDetails = () => {
  const [allSales, setAllSales] = useState([]);
  const [editOrderData, setEditOrderData] = useState(null);
  const Cred = useSelector((state) => state.Cred);
  const [loading, setLoading] = useState(-1);
  const { state } = useLocation();
  const isClient = useIsClient();
  const navigate = useNavigate();
  const [viewData, setViewData] = useState({
    loading: false,
    data: null,
    visible: false,
  });
  async function getCall() {
    setLoading(0);
    try {
      const resp = await getAllOrderByInvoiceNumber(
        state.invoiceNumber,
        Cred.token
      );
      setAllSales(resp);
    } catch (error) {
      Swal.fire({
        title: "Something went wrong!",
        text: "Can't Fetch Orders. Please try After Some Time",
        icon: "error",
      });
    }
    setLoading(-1);
  }

  const sales_col = useMemo(() => {
    const common_col = [
      {
        name: "Status",
        selector: (row) => formatStatus(row.status) || "No details",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Product",
        selector: (row) =>
          `${row?.productRes?.name} (${row?.productRes?.sku})` || "No details",
        sortable: true,
        width: "240px",
      },
      {
        name: "Quantity",
        selector: (row) => row.quantity || "No details",
        sortable: true,
        width: "100px",
      },
      {
        name: "GST",
        selector: (row) => row.gstAmount || 0,
        sortable: true,
      },
      {
        name: "Pre-GSt",
        selector: (row) => row.totalPrice || 0,
        sortable: true,
        width: "100px",
      },
      {
        name: "Post-GST",
        selector: (row) => row.totalPriceWithGst || 0,
        sortable: true,
        maxWidth: "200px",
      },
      {
        name: "DATE",
        selector: (row) =>
          row.orderCreatedDate
            ? getDateFormat(row.orderCreatedDate)
            : "No details",
        sortable: true,
        width: "100px",
      },
      {
        name: "ACTION",
        cell: (row) => {
          return (
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-outline-secondary"
                disabled={row.status === "DELIVERED"}
                onClick={() => setEditOrderData(row)}
              >
                <i className="icofont-edit text-success"></i>
              </button>
              <button
                type="button"
                onClick={() =>
                  setViewData({
                    data: row,
                    loading: true,
                    visible: true,
                  })
                }
                className="btn btn-outline-secondary"
              >
                <i className="icofont-eye text-primary size-1"></i>
              </button>
            </div>
          );
        },
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        width: "150px",
      },
    ];
    if (isClient) {
      common_col.splice(0, 0, {
        name: "MEMBER NAME",
        selector: (row) => row.memberName || "No details",
        sortable: true,
        width: "200px",
      });
    } else {
      common_col.splice(0, 0, {
        name: "CLIENT NAME",
        selector: (row) => row.clientName || "No details",
        sortable: true,
        width: "130px",
      });
    }

    return common_col;
  }, [allSales]);

  useEffect(() => {
    if (state === null) {
      navigate(-1);
    }
    getCall();
  }, []);

  const generateInvoice = useSalesDetailInvoice(allSales, state?.invoiceNumber);
  return {
    loading,
    allSales,
    editOrderData,
    setEditOrderData,
    sales_col,
    setAllSales,
    setViewData,
    viewData,
    invoiceNumber: state?.invoiceNumber ?? "",
    generateInvoice,
  };
};
