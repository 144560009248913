export const constants = {
  token_store: "PRISM_SFA_JWT_TOKEN",
  website_name: "Prism-SFA",
};

export const permissionIds = {
  CREATE_MANAGER: "Create_Manager",
  EDIT_MANAGER: "Edit_Manager",
  DELETE_MANAGER: "Delete_Manager",
  MANAGER: "Manager",
  VIEW_MANAGER: "View_Manager",
  CLIENT: "Client",
  CLIENT_FMCG: "ClientFMCG",
};

export const membersAllPermissions = [
  { label: "View Manager", value: "View_Manager" },
  { label: "Create Manager", value: "Create_Manager" },
  { label: "Edit Manager", value: "Edit_Manager" },
  { label: "Delete Manager", value: "Delete_Manager" },
  { label: "Manager (All Permissions including Master)", value: "Manager" },
];

export const permissionEnum = [
  { label: "Create_Manager", value: 0 },
  { label: "Edit_Manager", value: 1 },
  { label: "Delete_Manager", value: 2 },
  { label: "Manager", value: 3 },
  { label: "View_Manager", value: 4 },
];