import { useEffect, useMemo, useState } from "react";
import {
  getAllOrderByEachOutletByClientFMCGIdByOrderMediumApi,
  getAllOrderByEachOutletByClientFMCGIdByProductiveStatusApi,
  getAllOrderByEachOutletByMemberIdByOrderMediumApi,
  getAllOrderByEachOutletByMemberIdByProductiveStatusApi,
  getAllOrderByEachBeetByClientFMCGIdByOrderMediumApi,
  getAllOrderByEachBeetByClientFMCGIdByProductiveStatusApi,
  getAllOrderByEachBeetByMemberIdByOrderMediumApi,
  getAllOrderByEachBeetByMemberIdByProductiveStatusApi,
} from "../../../api/reports";

const useAllOrdersReportHook = () => {
  const [allData, setAllData] = useState({
    totalElements: 0,
    totalPages: 0,
    page: 0,
    content: [],
  });
  const [loading, setLoading] = useState(false);

  const [selectedReportTypeLevel, setSelectedReportTypeLevel] = useState(null);
  const [selectedReportIdLevel, setSelectedReportIdLevel] = useState(null);
  const [selectedReportCallLevel, setSelectedReportCallLevel] = useState(null);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(null);
  const [selectedOrderMedium, setSelectedOrderMedium] = useState(null);

  const getReportData = async (params) => {
    setLoading(true);

    try {
      const reportType = params?.reportType?.value;
      const reportId = params?.reportId?.value;
      const reportCall = params?.reportCall?.value;
      const orderStatus = params?.orderStatus?.value;
      const orderMedium = params?.orderMedium?.value;
      const memberClientId = params?.memberClientId?.value;

      if (reportType === "beet") {
        if (reportId === "member") {
          if (reportCall === "productive") {
            const resp =
              await getAllOrderByEachBeetByMemberIdByProductiveStatusApi(
                memberClientId,
                orderStatus,
              );
            setAllData(resp);
          } else if (reportCall === "order") {
            const resp =
              await getAllOrderByEachBeetByMemberIdByOrderMediumApi(
                memberClientId,
                orderMedium,
              );
            setAllData(resp);
          }
        } else if (reportId === "client") {
          if (reportCall === "productive") {
            const resp =
              await getAllOrderByEachBeetByClientFMCGIdByProductiveStatusApi(
                memberClientId,
                orderStatus,
              );
            setAllData(resp);
          } else if (reportCall === "order") {
            const resp =
              await getAllOrderByEachBeetByClientFMCGIdByOrderMediumApi(
                memberClientId,
                orderMedium,
              );
            setAllData(resp);
          }
        }
      } else if (reportType === "outlet") {
        if (reportId === "member") {
          if (reportCall === "productive") {
            const resp =
              await getAllOrderByEachOutletByMemberIdByProductiveStatusApi(
                memberClientId,
                orderStatus,
              );
            setAllData(resp);
          } else if (reportCall === "order") {
            const resp =
              await getAllOrderByEachOutletByMemberIdByOrderMediumApi(
                memberClientId,
                orderMedium,
              );
            setAllData(resp);
          }
        } else if (reportId === "client") {
          if (reportCall === "productive") {
            const resp =
              await getAllOrderByEachOutletByClientFMCGIdByProductiveStatusApi(
                memberClientId,
                orderStatus,
              );
            setAllData(resp);
          } else if (reportCall === "order") {
            const resp =
              await getAllOrderByEachOutletByClientFMCGIdByOrderMediumApi(
                memberClientId,
                orderMedium,
              );
            setAllData(resp);
          }
        }
      }
    } catch (error) {
      console.error("Failed to fetch report data", error);
    }

    setLoading(false);
  };

  // useEffect(() => {
  //   if (
  //     selectedReportTypeLevel &&
  //     selectedReportIdLevel &&
  //     selectedReportCallLevel
  //   ) {
  //     getReportData({
  //       reportType: selectedReportTypeLevel,
  //       reportId: selectedReportIdLevel,
  //       reportCall: selectedReportCallLevel,
  //       orderStatus: selectedOrderStatus,
  //       orderMedium: selectedOrderMedium,
  //     });
  //   }
  // }, [
  //   selectedReportTypeLevel,
  //   selectedReportIdLevel,
  //   selectedReportCallLevel,
  //   selectedOrderStatus,
  //   selectedOrderMedium,
  // ]);

  const columns = useMemo(() => {
    if (selectedReportTypeLevel?.value === "beet") {
      return [
        { name: "S.No", selector: (_, index) => index + 1, sortable: true },
        {
          name: "Beet",
          selector: (row) => row.beetRespForOrderDto.beet,
          sortable: true,
        },
        {
          name: "Address",
          selector: (row) => row.beetRespForOrderDto.address,
          sortable: true,
        },
        {
          name: "Postal Code",
          selector: (row) => row.beetRespForOrderDto.postalCode,
          sortable: true,
        },
        {
          name: "State",
          selector: (row) => row.beetRespForOrderDto.state,
          sortable: true,
        },
        {
          name: "City",
          selector: (row) => row.beetRespForOrderDto.city,
          sortable: true,
        },
        {
          name: "Total Sales",
          selector: (row) => row.totalSales,
          sortable: true,
        },
        {
          name: "Total Orders",
          selector: (row) => row.totalOrder,
          sortable: true,
        },
      ];
    }

    return [
      { name: "S.No", selector: (_, index) => index + 1, sortable: true },
      {
        name: "Outlet Name",
        selector: (row) => row.outletRespForOrderDto.outletName,
        sortable: true,
      },
      {
        name: "Outlet Type",
        selector: (row) => row.outletRespForOrderDto.outletType,
        sortable: true,
      },
      {
        name: "Owner Name",
        selector: (row) => row.outletRespForOrderDto.ownerName,
        sortable: true,
      },
      {
        name: "Total Sales",
        selector: (row) => row.totalSales,
        sortable: true,
      },
      {
        name: "Total Orders",
        selector: (row) => row.totalOrder,
        sortable: true,
      },
    ];
  }, [selectedReportTypeLevel]);

  return {
    selectedReportTypeLevel,
    selectedReportIdLevel,
    selectedReportCallLevel,
    selectedOrderStatus,
    selectedOrderMedium,
    setSelectedReportTypeLevel,
    setSelectedReportIdLevel,
    setSelectedReportCallLevel,
    setSelectedOrderStatus,
    setSelectedOrderMedium,
    getReportData,
    loading,
    allData,
    columns,
  };
};

export default useAllOrdersReportHook;
